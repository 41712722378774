'use strict';

angular.module('cpformplastApp')
  .config(function ($stateProvider) {
    $stateProvider
      .state('dashboard', {
        url: '/dashboard',
        templateUrl: 'app/dashboard/dashboard.html',
        controller: 'DashboardController',
        authenticate: ['admin','management_dept','receptionist']
      });
  });
